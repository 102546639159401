import { Box } from "@mui/system";
import ProSidebarComponent from "../../components/ProSidebarComponent";

import { colors } from "../../theme";

const ClosedSideBar = () => {
  return (
    <Box
      sx={{
        height: "100vh",
        display: { xs: "none", sm: "block", lg: "none" },
        "& .pro-sidebar-inner": {
          background: `${colors.primary[400]} !important`,
        },
        "& .pro-icon-wrapper": {
          backgroundColor: "transparent !important",
        },
        "& .pro-inner-item": {
          padding: "5px 35px 5px 20px !important",
        },
        "& .pro-inner-item:hover": {
          color: "#868dfb !important",
        },
        "& .pro-menu-item.active": {
          color: "#6870fa !important",
        },
      }}
    >
      <ProSidebarComponent isCollapsed closed />
    </Box>
  );
};

export default ClosedSideBar;
