import { Box } from "@mui/system";
import React from "react";
import { Header } from "../../components/Header";
import Service from "../../components/Service";
import { serviceData } from "../../data/app.data";

const Services = () => {
  return (
    <Box padding="30px">
      <Header title="Services" subTitle="Few Things In Which I Am Good" />
      <Box
        width="100%"
        sx={{ overflowY: "auto" }}
        height={{ xs: "75vh", sm: "80vh" }}
      >
        {serviceData.map((item) => {
          return (
            <Service
              id={item.id}
              key={item.id}
              icon={item.icon}
              title={item.title}
              summery={item.summery}
            />
          );
        })}
      </Box>
    </Box>
  );
};

export default Services;
