import React, { useState } from "react";
import {
  AccountTreeOutlined,
  ContactPhoneOutlined,
  DesignServicesOutlined,
  HandymanOutlined,
  HomeOutlined,
  MenuOutlined,
  SchoolOutlined,
} from "@mui/icons-material";
import { Menu, MenuItem, ProSidebar } from "react-pro-sidebar";
import { Box, IconButton, Typography } from "@mui/material";
import "react-pro-sidebar/dist/css/styles.css";
import { Link } from "react-router-dom";
import { colors } from "../theme";

const Item = ({ title, to, icon, selected, setSelected }) => {
  return (
    <MenuItem
      active={selected === title}
      icon={icon}
      style={{ color: colors.grey[100] }}
      onClick={() => {
        setSelected(title);
      }}
    >
      <Typography>{title}</Typography>
      <Link to={to} />
    </MenuItem>
  );
};

const ProSidebarComponent = ({ isCollapsed, setIsCollapsed, closed }) => {
  const [selected, setSelected] = useState("Home");
  return (
    <ProSidebar collapsed={isCollapsed}>
      <Menu iconShape="square">
        {!closed && (
          <MenuItem
            onClick={() => {
              setIsCollapsed(!isCollapsed);
            }}
            icon={isCollapsed ? <MenuOutlined /> : undefined}
            style={{ margin: "10px 0 20px 0", color: colors.grey[100] }}
          >
            {!isCollapsed && (
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                ml="15px"
              >
                <Box />
                <IconButton
                  onClick={() => {
                    setIsCollapsed(!isCollapsed);
                  }}
                  sx={{ color: colors.grey[100] }}
                >
                  <MenuOutlined />
                </IconButton>
              </Box>
            )}
          </MenuItem>
        )}

        {!isCollapsed && (
          <Box mb="25px">
            <Box display="flex" justifyContent="center" alignItems="center">
              <img
                width="100px"
                height="100px"
                alt="user-profile"
                src="/images/pratik.png"
                style={{ cursor: "pointer", borderRadius: "50%" }}
              />
            </Box>
            <Box textAlign="center">
              <Typography
                variant="h2"
                color={colors.grey[100]}
                fontWeight="bold"
                sx={{ margin: "10px 0 0 0" }}
              >
                Pratik S
              </Typography>
              <Typography variant="h5" color={colors.greenAccent[500]}>
                A Web Developer
              </Typography>
            </Box>
          </Box>
        )}

        <Box
          //   marginTop={closed ? "100%" : undefined}
          paddingLeft={isCollapsed ? undefined : "10%"}
        >
          <Item
            title="Home"
            to="/"
            icon={<HomeOutlined />}
            selected={selected}
            setSelected={setSelected}
          />
          <Typography
            variant="h6"
            color={colors.grey[300]}
            sx={{ margin: "15px 0 5px 20px" }}
          >
            About
          </Typography>
          <Item
            title="Education"
            to="/education"
            icon={<SchoolOutlined />}
            selected={selected}
            setSelected={setSelected}
          />
          <Item
            title="Skills"
            to="/skills"
            icon={<HandymanOutlined />}
            selected={selected}
            setSelected={setSelected}
          />
          <Item
            title="Projects"
            to="/projects"
            icon={<AccountTreeOutlined />}
            selected={selected}
            setSelected={setSelected}
          />
          <Item
            title="Services"
            to="/services"
            icon={<DesignServicesOutlined />}
            selected={selected}
            setSelected={setSelected}
          />
          <Item
            title="Contact"
            to="/contact"
            icon={<ContactPhoneOutlined />}
            selected={selected}
            setSelected={setSelected}
          />
        </Box>
      </Menu>
    </ProSidebar>
  );
};

export default ProSidebarComponent;
