import { Box, Paper, Stack, Typography } from "@mui/material";
import { Header } from "../../components/Header";
import React from "react";
import { colors } from "../../theme";
import {
  FaInstagram,
  FaLinkedin,
  // FaTwitter,
  FaWhatsapp,
  FaYoutube,
} from "react-icons/fa";
import { Email } from "@mui/icons-material";

const Contact = () => {
  return (
    <Box padding="30px" width="100%">
      <Header title="Contact Me" />
      <Paper
        elevation={12}
        sx={{
          bgcolor: colors.primary[400],
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: "20px",
        }}
      >
        <Typography variant="h5" color={colors.greenAccent[400]}>
          You Can Contact Me By
        </Typography>
        <Stack mt="10px" direction={{ xs: "column", md: "row" }} spacing={5}>
          <Stack direction="row" spacing={1}>
            <FaWhatsapp size={24} style={{ color: colors.blueAccent[400] }} />
            <Typography variant="h5" color={colors.grey[200]}>
              +91 7262804491
            </Typography>
          </Stack>

          <Stack direction="row" spacing={1}>
            <Email sx={{ color: colors.blueAccent[400] }} />
            <Typography variant="h5" color={colors.grey[200]}>
              pratikshinde7262@gmail.com
            </Typography>
          </Stack>
        </Stack>
      </Paper>
      <Paper
        elevation={12}
        sx={{
          bgcolor: colors.primary[400],
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: "20px",
          mt: "30px",
        }}
      >
        <Typography variant="h5" color={colors.greenAccent[400]}>
          You Can Find Me On Social Media
        </Typography>
        <Stack direction="row" mt="20px" spacing={2}>
          <a
            rel="noreferrer"
            href="https://www.linkedin.com/in/pratik-shinde-156993213"
            target="_blank"
          >
            <FaLinkedin size={24} style={{ color: colors.blueAccent[400] }} />
          </a>
          <a
            rel="noreferrer"
            href="https://www.youtube.com/channel/UCRaj3KHYyN4MghIuujFMVEQ"
            target="_blank"
          >
            <FaYoutube size={24} style={{ color: colors.blueAccent[400] }} />
          </a>
          <a
            rel="noreferrer"
            href="https://instagram.com/pratik_72_62"
            target="_blank"
          >
            <FaInstagram size={24} style={{ color: colors.blueAccent[400] }} />
          </a>

          {/* <a rel="noreferrer" href="https://twitter.com/" target="_blank">
            <FaTwitter size={24} style={{ color: colors.blueAccent[400] }} />
          </a> */}
        </Stack>
      </Paper>
    </Box>
  );
};

export default Contact;
