import { Box } from "@mui/material";
import React from "react";
import { Header } from "../../components/Header";
import Info from "../../components/Info";
import { educationData } from "../../data/app.data";

const Education = () => {
  return (
    <Box padding="30px" width="100%">
      <Header title="Education" subTitle="Academic Journey" />
      <Box
        width="100%"
        height={{ xs: "75vh", sm: "80vh" }}
        sx={{ overflowY: "auto" }}
      >
        {educationData.map((item) => {
          return (
            <Info
              key={item.id}
              title={item.title}
              id={item.id}
              summery={item.summery}
            />
          );
        })}
      </Box>
    </Box>
  );
};

export default Education;
