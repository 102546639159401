import { Box } from "@mui/material";
import React from "react";
import Carousel from "react-material-ui-carousel";

const CarouselComponent = ({ imgs }) => {
  return (
    <Carousel
      // animation="slide"
      duration={200}
      // navButtonsAlwaysVisible
      sx={{ width: "100%" }}
    >
      {imgs.map((item, i) => (
        <Box key={i}>
          <img alt={i} style={{ width: "100%", height: 200 }} src={item.img} />
        </Box>
      ))}
    </Carousel>
  );
};

export default CarouselComponent;
