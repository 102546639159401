import { Box, Button, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import { colors } from "../../theme";

const MyBtn = ({ text, width, to }) => {
  return (
    <Link to={to} style={{ textDecoration: "none" }}>
      <Button
        variant="outlined"
        sx={{
          width: width,
          color: colors.grey[100],
          border: `1px solid ${colors.grey[100]}`,
          "&:hover": { border: `1px solid` },
          borderRadius: "30px",
          my: 1,
        }}
      >
        {text}
      </Button>
    </Link>
  );
};

const Home = () => {
  return (
    <>
      <Box
        height="100vh"
        width="100%"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <Box display="flex" justifyContent="center" alignItems="center">
          <img
            width="100px"
            height="100px"
            alt="user-profile"
            src="/images/pratik.png"
            style={{ cursor: "pointer", borderRadius: "50%" }}
          />
        </Box>
        <Box width={{ xs: "90vw", lg: "50vw" }}>
          <Typography
            variant="h1"
            fontWeight={700}
            textAlign="center"
            color={colors.grey[100]}
            mt="30px"
          >
            Hi, I Am Pratik Shinde
          </Typography>
          <Typography
            textAlign="center"
            variant="h3"
            sx={{ mt: 1, mb: 2 }}
            color={colors.greenAccent[500]}
          >
            A passionate and creative full-stack web developer.
          </Typography>

          <Typography
            variant="h5"
            textAlign="center"
            color={colors.grey[100]}
            mb="40px"
          >
            Welcome to my world of web development! Explore my portfolio to see
            how I turn ideas into captivating web experiences.
          </Typography>
        </Box>
        <Box
          sx={{
            width: { xs: "90vw", sm: "500px" },
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            flexDirection: { xs: "column", sm: "row" },
          }}
        >
          <MyBtn text="Take A look Of My Work" width="200px" to="/projects" />
          <MyBtn text="Contact Me" width="200px" to="/contact" />
        </Box>
      </Box>
    </>
  );
};

export default Home;
