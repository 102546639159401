import { Close } from "@mui/icons-material";
import { IconButton, Stack, Tooltip } from "@mui/material";
import { Box } from "@mui/system";
import ProSidebarComponent from "../../components/ProSidebarComponent";

import { colors } from "../../theme";

const OpenedSidebar = ({ onClose }) => {
  return (
    <Box
      sx={{
        width: "100%",
        display: { xs: "block", sm: "none" },
        height: "97vh",
        "& .pro-sidebar-inner": {
          background: `${colors.primary[400]} !important`,
        },
        "& .pro-icon-wrapper": {
          backgroundColor: "transparent !important",
        },
        "& .pro-inner-item": {
          padding: "5px 35px 5px 20px !important",
        },
        "& .pro-inner-item:hover": {
          color: "#868dfb !important",
        },
        "& .pro-menu-item.active": {
          color: "#6870fa !important",
        },
      }}
    >
      <Stack
        direction="row-reverse"
        sx={{
          bgcolor: colors.primary[400],
          m: 0,
          width: "100%",
          height: "3vh",
        }}
      >
        <Tooltip sx={{ m: 0 }} title="close">
          <IconButton onClick={onClose} sx={{ m: 0, color: colors.grey[100] }}>
            <Close />
          </IconButton>
        </Tooltip>
      </Stack>
      <ProSidebarComponent isCollapsed={false} closed />
    </Box>
  );
};

export default OpenedSidebar;
