import { MenuOutlined } from "@mui/icons-material";
import { Drawer, IconButton, Stack } from "@mui/material";
import { useState } from "react";
import { Route, Routes } from "react-router-dom";
import ProjectCard from "./components/ProjectCard";
import Contact from "./scenes/contact";
import Education from "./scenes/education";
// import BottomNav from "./scenes/global/BottomNav";
import ClosedSideBar from "./scenes/global/ClosedSidebar";
import OpenedSidebar from "./scenes/global/OpendeSidebar";
import SideBar from "./scenes/global/SideBar";
import Home from "./scenes/home";
import Projects from "./scenes/projects";
import Services from "./scenes/services";
import Skills from "./scenes/skills";
import { colors } from "./theme";

function App() {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [open, setOpen] = useState(false);

  const onClick = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };
  return (
    <Stack
      direction={{ xs: "column", sm: "row" }}
      sx={{ position: "relative", width: "100vw", height: "100vh" }}
    >
      <SideBar isCollapsed={isCollapsed} setIsCollapsed={setIsCollapsed} />
      <ClosedSideBar />
      {/* <BottomNav /> */}
      <Drawer open={open} onClose={onClose} anchor="left">
        <OpenedSidebar onClose={onClose} />
      </Drawer>
      <Stack
        direction="row"
        sx={{
          width: "100vw",
          height: "5vh",
          display: { xs: "flex", sm: "none" },
          borderBottom: `1px solid ${colors.grey[100]}`,
        }}
      >
        <IconButton sx={{ color: colors.grey[100] }} onClick={onClick}>
          <MenuOutlined />
        </IconButton>
      </Stack>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/education" element={<Education />} />
        <Route path="/skills" element={<Skills />} />
        <Route
          path="/projects"
          element={<Projects isCollapsed={isCollapsed} />}
        />
        <Route path="/services" element={<Services />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/carousel" element={<ProjectCard />} />
      </Routes>
    </Stack>
  );
}

export default App;
