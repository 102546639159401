import { Paper, Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { colors } from "../theme";

const Service = ({ title, summery, icon, id }) => {
  return (
    <Stack
      direction={id % 2 === 0 ? "row-reverse" : "row"}
      sx={{ width: "100%" }}
    >
      <Paper
        sx={{
          m: "10px",
          bgcolor: colors.primary[400],
          padding: "20px",
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
        }}
        elevation={12}
      >
        <Box mr={4}>{icon}</Box>
        <Box>
          <Typography variant="h5" color={colors.greenAccent[400]}>
            {title}
          </Typography>
          <Typography variant="body1" textAlign="left" color={colors.grey[100]}>
            {summery}
          </Typography>
        </Box>
      </Paper>
    </Stack>
  );
};

export default Service;
