import React from "react";
import { Box, Typography } from "@mui/material";
import { colors } from "../theme";

export const Header = (props) => {
  return (
    <Box sx={{ mb: "30px" }}>
      <Typography variant="h2" color={colors.grey[100]} sx={{ mb: "5px" }}>
        {props.title}
      </Typography>
      <Typography variant="h5" color={colors.greenAccent[400]}>
        {props.subTitle}
      </Typography>
    </Box>
  );
};
