import {
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  Link,
  Typography,
} from "@mui/material";
import React from "react";
import { colors } from "../theme";
import CarouselComponent from "./CarouselComponent";

const ProjectCard = ({ project }) => {
  return (
    <Grid key={project.id} xs={12} sm={4} item>
      <Card
        sx={{
          bgcolor: colors.primary[400],
          width: "100%",
          maxHeight: "80vh",
          p: 0,
          m: 0,
        }}
      >
        <CarouselComponent imgs={project.imgs} />
        <CardContent>
          <Typography
            color={colors.greenAccent[500]}
            textAlign="left"
            variant="h5"
            fontWeight={700}
          >
            {project.title}
          </Typography>
          <Typography color={colors.grey[100]} textAlign="left" variant="h6">
            {project.summery}
          </Typography>
        </CardContent>
        <CardActions
          sx={{
            justifyContent: "space-between",
          }}
        >
          <Link target="_blank" href={project.source}>
            <Button
              disabled={!project.isHosted}
              color="blue"
              variant="contained"
            >
              Visit Website
            </Button>
          </Link>
          <Link target="_blank" href={project.gUrl}>
            <Button color="blue" variant="contained">
              View Code
            </Button>
          </Link>
        </CardActions>
      </Card>
    </Grid>
  );
};

export default ProjectCard;
 