import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import { Header } from "../../components/Header";
import ProjectCard from "../../components/ProjectCard";
import {
  frontendProjectData,
  fullStackProjectsData,
} from "../../data/app.data";
import { colors } from "../../theme";

const Projects = () => {
  return (
    <Box padding="30px 10px" sx={{ overflowY: "auto", height: "100vh" }}>
      <Header title="PROJECTS" />
      <Typography
        variant="h2"
        color={colors.greenAccent[500]}
        sx={{ m: "10px" }}
      >
        MERN Projects
      </Typography>
      <Grid
        rowSpacing={3}
        direction="row"
        columnSpacing={{ xs: 0, sm: 1 }}
        container
        ml={{ sm: undefined }}
        width="100%"
      >
        {fullStackProjectsData.map((project, i) => {
          return <ProjectCard key={i} project={project} />;
        })}
      </Grid>
      <Typography
        variant="h2"
        color={colors.greenAccent[500]}
        sx={{ m: "10px" }}
      >
        Frontend Projects
      </Typography>
      <Grid
        rowSpacing={3}
        direction="row"
        columnSpacing={{ xs: 0, sm: 1 }}
        container
        ml={{ sm: undefined }}
        width="100%"
      >
        {frontendProjectData.map((project, i) => {
          return <ProjectCard key={i} project={project} />;
        })}
      </Grid>
    </Box>
  );
};

export default Projects;
