import { Box } from "@mui/material";
import React from "react";
import { Header } from "../../components/Header";
import Info from "../../components/Info";
import { skillData } from "../../data/app.data";

const Skills = () => {
  return (
    <Box width="100%" padding="30px">
      <Header title="SKILLS" />
      <Box
        width="100%"
        height={{ xs: "75vh", sm: "85vh" }}
        sx={{ overflowY: "auto" }}
      >
        {skillData.map((item) => {
          return (
            <Info
              key={item.id}
              title={item.title}
              id={item.id}
              summery={item.summery}
            />
          );
        })}
      </Box>
    </Box>
  );
};

export default Skills;
