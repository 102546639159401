import { Paper, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import React from "react";
import { colors } from "../theme";

const Info = ({ title, summery, id }) => {
  return (
    <Stack
      direction={id % 2 === 0 ? "row-reverse" : "row"}
      sx={{ width: "100%" }}
    >
      <Paper
        elevation={12}
        sx={{
          bgcolor: colors.primary[400],
          padding: "30px",
          margin: "15px 0",
          width: { xs: "90vw", sm: "50vw" },
        }}
      >
        <Typography
          variant="h5"
          sx={{ display: "inline" }}
          color={colors.greenAccent[400]}
        >
          {title}:-{"  "}
        </Typography>
        <Typography
          variant="h5"
          sx={{ display: "inline" }}
          textAlign="left"
          color={colors.grey[100]}
        >
          {summery}
        </Typography>
      </Paper>
    </Stack>
  );
};

export default Info;
